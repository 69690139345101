import axios from 'axios';
import { jwtHelper, apiHelper } from '@/core/utils';
import { API_CONSTANTS } from '@/core/constants';

//Reference https://github.com/axios/axios

export function apiService(
  URL = null,
  isAuth = true,
  withMedia = false,
  setLastActivityTime = true
) {
  let baseURL = URL === null ? API_CONSTANTS.BASE_API_URL : URL;
  let jwtToken = jwtHelper.getBearerToken();
  let header = isAuth
    ? {
        Authorization: jwtToken,
        'Content-Type': withMedia ? 'multipart/form-data' : 'application/json'
      }
    : {};

  let instance = axios.create({
    baseURL,
    headers: header,
    withCredentials: false
  });

  instance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      if (config.method.toUpperCase() === 'PUT') {
        // Change the method to POST
        config.method = 'POST';

        // Add the X-HTTP-Method-Override header
        config.headers['X-HTTP-Method-Override'] = 'PUT';
      }

      if (config.method.toUpperCase() === 'DELETE') {
        // Change the method to POST
        config.method = 'POST';

        // Add the X-HTTP-Method-Override header
        config.headers['X-HTTP-Method-Override'] = 'DELETE';
      }

      //Remove empty value params
      config.params = apiHelper.removeParamsWithoutValue(config.params);

      //Update activity time when fire API
      if (setLastActivityTime) jwtHelper.setLastActivityTime();

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      let res = response.data;

      return res;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      let err = error.response ? error.response.data : error;

      return Promise.reject(err);
    }
  );

  return instance;
}
